<template>
  <div class="big-body">
    <div class="top-wrapper">
      <!-- <img src="/img/icons/TTbg.jpeg" alt="" srcset=""> -->

      <div class="inner-top-wrapper">
        <div class="first-content">
          <div class="inner-container">
            <div class="first">
              At INCREDEVENT, it's important that we provide you with an easy
              and quick ticketing solution. You should be able to complete your
              purchase in minutes, and have plenty of options to make your life
              easier. We work daily to integrate new ticketing technologies to
              achieve this goal, while making sure your purchase is secure (our
              fraud protection, and SSL certification are second to none).
            </div>
            <div class="second">
              So, take a look below at our many features available to you. While
              you're at it, you can also check out our tutorials before buying a
              ticket, so that you can take advantage of all INCREDEVENT
              features.
            </div>
          </div>
        </div>

        <div class="flex-wrapper container">
          <div class="content-box left">
            <div class="content-image-box">
              <img
                class="content-image"
                src="@/images/icons/icon-ticket.png"
                alt="Buy Tickets"
              />
              <h4>Buy Tickets</h4>
            </div>
            <div class="box-content">
              Purchase your tickets with peace of mind: when you buy on
              INCREDEVENT, you're buying a secure, unique ticket that can only
              be used once. You're also paying some of the lowest fees for
              tickets in the industry. In minutes, you'll have the ticket you
              want emailed to you, and if you have any issues at all ... you can
              just talk to one of our live chat specialists. Now that's customer
              service.
            </div>
          </div>
          <div class="content-box right">
            <div class="content-image-box">
              <img
                class="content-image"
                src="@/images/icons/icon-tickethistory.png"
                alt="Ticket History"
              />
              <h4>Ticket History (Forever)</h4>
            </div>
            <div class="box-content">
              Can't find the email we sent you with your ticket? Perhaps your
              spam filters are extremely aggressive. Not a problem with
              INCREDEVENT. Just login to your account and get instant access to
              every ticket you've ever purchased from us; past, present, and
              future. Now you have proof that you went to that concert where
              that crazy thing happened to tell your kids. Isn't tech fun?
            </div>
          </div>
          <div class="content-box left">
            <div class="content-image-box">
              <img
                class="content-image"
                src="@/images/icons/icon-lightning.png"
                alt="Updates"
              />
              <h4>Lightning Fast Events Updates</h4>
            </div>
            <div class="box-content">
              Sometimes a new act is added to the show, or weather causes
              delays. You'll never be left wondering what's going on with the
              next event you're attending, because ticket sellers can send you
              instant updates to your email. You can also reply directly to the
              ticket seller to get more information that you need before heading
              out to the show. Enjoy piece of mind knowing you're always
              up-to-date.
            </div>
          </div>
          <div class="content-box right">
            <div class="content-image-box">
              <img
                class="content-image"
                src="@/images/icons/icon-payment.png"
                alt="Payment Options"
              />
              <h4>Tons Of Payment Options</h4>
            </div>
            <div class="box-content">
              Visa, Mastercard, American Express, Credit Debit, PayPal, Discover
              (US only) , JCB (US only) ... well, we'd like to think we have
              everyone covered. Add to that, if you buy tickets through official
              event promoters, you can pay with cash, and you have a ticketing
              website with the most payment options on Earth.
            </div>
          </div>
          <div class="content-box left">
            <div class="content-image-box">
              <img
                class="content-image"
                src="@/images/icons/icon-trophy.png"
                alt="Contests"
              />
              <h4>Enter Contests & Win</h4>
            </div>
            <div class="box-content">
              When you buy a ticket to a INCREDEVENT hosted event, you also get
              entered into our contesting and promotions system. Get access to
              exclusive ticket and merchandise giveaways right in your inbox. We
              adhere to strict spam policies. We don't email very often, and we
              never email after someone has unsubscribed from our list. So, I
              guess if you get tired of contests, you're covered.
            </div>
          </div>
          <div class="content-box right">
            <div class="content-image-box">
              <img
                class="content-image"
                src="@/images/icons/icon-diamond.png"
                alt="Exclusive Offers"
              />
              <h4>Access Exclusive Offers</h4>
            </div>
            <div class="box-content">
              Ticket sellers love to offer exclusive pre-codes or meet and
              greets to buyers, so consider buying tickets with us to be your
              membership into an exclusive club. We've also mastered the
              connection between past and future events. Let's say you went to a
              Rolling Stones concert. Now, the same promoter is bringing Paul
              McCartney; you'll know before everyone else.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.container {
  max-width: 1200px;
  margin: auto;
}

.inner-container {
  max-width: 1100px;
  // width: 90%;
  margin: auto;
}

.first {
  // margin: 0 5em;
  margin-bottom: 2em;
}

.second {
  // margin: 0 5em;
}

.big-body {
  -webkit-box-shadow: inset 0 0 50px 40px #171717e0;
  box-shadow: inset 0 0 50px 40px #171717e0;
  background-color: #222222;
}

.img-a {
  height: 85px;
  width: 85px;
  margin: 0 0.75em;
}

.img {
  height: 85px;
  width: 85px;
}

.box-content {
  font-size: 14px;
  margin-top: 65px;
  color: var(--text);
  line-height: 24px;

  // margin: 2em 2em;
}

.content-box {
  display: block;
  // width: 40%;
  height: fit-content;
  position: relative;
  backdrop-filter: blur(4px);
  padding: 20px;
  margin: 0px 20px 80px;
  max-width: 480px;
  width: 100%;
  // justify-content: center;
  border: 1px solid var(--dashboard-border);
  border-radius: 10px;
  text-align: left;
  // background-color: rgba(0, 0, 0, 0.1);
  background-image: var(--dashboard-tiles);
  box-shadow: var(--other-shadow);
}

.content-image-box {
  width: 100%;
  position: absolute;
  top: -15%;
  right: 0;
  text-align: center;

  h4 {
    margin: 10px 0;
    color: var(--text);
    font-weight: 700;
  }
}

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sign-up-btn {
  background: #4fb95e;
  border: 0px;
  font-weight: 100;
  color: #ffffff;
  padding: 9px 55px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0px;
  box-shadow: none;
  transition: all 0.5s ease-out;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  margin: 4em 0;
}

.top-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-image: url("../images/TTSplashBG.jpg");

  // background-image: var(--features-splash);
  background-size: contain;
  background-repeat: no-repeat;
  // background-position: center;
  background-color: var(--content-background);
}
#app.light {
  .top-wrapper {
    background-image: url("../images/TTSplash-Light.jpg");
  }
  // background-image: var(--features-splash);
}
.inner-top-wrapper {
  text-align: center;
}

h3 {
  margin: 1em 0;
  font-size: 2.25em;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.router-link {
  width: 310px;
  padding: 5px 0;
  text-align: center;
  background-color: #419e4f;
  border: none;
  color: #ffffff;
}

.first-content {
  padding: 80px 20px;
  font-size: 14px;
  text-align: left;
  font-weight: 100;
}
</style>

<script>
export default {
  name: "buyer-features",
  head() {
    return {
      title: "Find & Plan Your Next Adventure • Buyer Features • INCREDEVENT",
      meta: [
        {
          name: "description",
          content:
            "Find your next event & plan your experience with INCREDEVENT. Never lose a ticket or miss an event again! How? Take a look at our Buyer Features.",
        },
        {
          name: "keywords",
          content: "Events, Tickets, Directory, Promotion",
        },
        {
          name: "author",
          content: "INCREDEVENT",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
      ],
    };
  },
  created() {
    this.$store.commit("setTitle", "Buyer Features");
  },
};
</script>
